import api from "../stores/api";


export const getVehicles = (query = {}) => {
  let params = new URLSearchParams();

  Object.keys(query).map(t => {
    params.append(t, query[t]);
  });

  return api.get(`/vehicles?${params}`);
};

export const getCars = (query) => {
  let params = new URLSearchParams();

  Object.keys(query).map(t => {
    params.append(t, query[t]);
  });

  return api.get(`/vehicles/cars?${params}`)
};

export const vehicleUpsert = (query) => {
  let forms = new FormData();
  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  Object.keys(query).map(t => {
    forms.append(t, query[t]);
  });

  return api.post(`/vehicles/upsert`, forms, headers)
};

export const vehicleRemove = (id) => {
  if(!id) return;

  return api.delete(`/vehicles/delete/${id}`)
};


export const getFile = (id) => api.get(`/vehicles/file/${id}`);
export const getUserList = () => api.get('/couriers/users/list');
export const getUserType = () => api.get('/couriers/list-dsp-acl-group');
export const createUser = (data) => {
  return api.post(`/couriers/users`, data)
};