import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import validate from 'validate.js';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Container, Grid, Button, Box, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import AxlDialog from 'components/AxlDialog';
import TextInput from 'components/input/TextInput';

import acl from 'constants/acl';
import { RULES } from 'constants/rules';
import { BANK_REQUEST_STATUS } from 'constants/colors';

import styles from './styles';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBankConfirmation: false,
    };
  }

  validates = {
    company: ['notEmpty'],
    email: ['notEmpty', 'email'],
    contact_name: ['notEmpty'],
    phone_number: ['notEmpty', 'phone'],
    bank_routing: ['notEmpty', 'number'],
    bank_account: ['notEmpty', 'number'],
  };

  componentDidMount() {
    const { store } = this.props;
    store.userStore.getLatestBankRequest();
  }

  onTextFieldChange = (field) => (event) => {
    const { store } = this.props;

    store.userStore.updateDSPData(field, event.target.value);
    const fieldValidates = this.validates[field];

    if (fieldValidates) {
      this.checkError(field);
    }
  };

  accountInfoFields = ['company', 'phone_number', 'email', 'contact_name'];
  bankInfoFields = ['bank_routing', 'bank_account'];

  checkError = (field, prefix) => {
    const { store } = this.props;
    const { accountData } = store.userStore;
    if (!prefix) {
      const initErrorField = 'init_' + field + '_error';
      this.setState({ [initErrorField]: undefined });
    }

    const errorField = prefix ? prefix + field + '_error' : field + '_error';

    const validates = this.validates[field];

    if (!validates) return;
    const value = accountData[field];

    var constraints = {
      [field]: _.assign({}, ..._.values(_.pick(RULES, validates))),
    };

    const result = validate({ [field]: value }, constraints, { format: 'flat' });

    if (!result) this.setState({ [errorField]: undefined });
    else {
      this.setState({ [errorField]: result[0] });
    }
  };

  save = () => {
    const { store } = this.props;
    store.userStore.updateDsp();
  };

  submitBankRequest = () => {
    const { store } = this.props;
    store.userStore.submitBankInfoRequest((res) => {
      if (res.ok) {
        this.setState({ showBankConfirmation: true });
      }
    });
  };

  render() {
    const { showBankConfirmation } = this.state;
    const { classes, store } = this.props;
    const { userStore, permissionStore } = store;
    const { updating, getAccountDataValue, bankRequest } = userStore;

    const hasAccountError = this.accountInfoFields.find((field) => !!this.state[field + '_error'] || !!this.state['init_' + field + '_error']);
    const hasBankError = this.bankInfoFields.find((field) => !!this.state[field + '_error'] || !!this.state['init_' + field + '_error']);

    const isDeniedUpdateAccountInfo = permissionStore.isDenied(acl.PROFILE.ACCOUNT_INFO);
    const isDeniedUpdateBankInfo = permissionStore.isDenied(acl.PROFILE.BANK_INFO);

    return (
      <Container>
        <h3>Account Settings</h3>
        <Box style={{ position: 'relative' }}>
          {updating && (
            <Box className={classes.overlay}>
              <CircularProgress color="primary" size={70} thickness={2} />
            </Box>
          )}
          <Box className={classes.root}>
            <Box component="h3" my={1}>
              Account Information
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextInput
                  onChange={this.onTextFieldChange('company')}
                  value={getAccountDataValue('company')}
                  error={this.state['company_error']}
                  placeholder=""
                  label="COMPANY"
                  disabled={isDeniedUpdateAccountInfo}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  onChange={this.onTextFieldChange('contact_name')}
                  value={getAccountDataValue('contact_name')}
                  error={this.state['contact_name_error']}
                  placeholder=""
                  label="NAME OF CONTACT"
                  disabled={isDeniedUpdateAccountInfo}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  onChange={this.onTextFieldChange('phone_number')}
                  value={getAccountDataValue('phone_number')}
                  error={this.state['phone_number_error']}
                  placeholder=""
                  label="CONTACT PHONE NUMBER"
                  disabled={isDeniedUpdateAccountInfo}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  onChange={this.onTextFieldChange('email')}
                  value={getAccountDataValue('email')}
                  error={this.state['email_error']}
                  placeholder=""
                  label="CONTACT EMAIL"
                  disabled={isDeniedUpdateAccountInfo}
                />
              </Grid>
            </Grid>
            <Box textAlign="right" mt={2}>
              {!isDeniedUpdateAccountInfo && (
                <Button
                  disabled={!!hasAccountError || updating}
                  onClick={this.save}
                  className={classes.mainBtn}
                  size="medium"
                  disableElevation={true}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              )}
            </Box>
          </Box>

          <Box className={classes.root} mt={3}>
            <Box component="h3" my={1}>
              Bank Information
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextInput
                  onChange={this.onTextFieldChange('bank_account')}
                  value={getAccountDataValue('bank_account')}
                  error={this.state['bank_account_error']}
                  placeholder=""
                  label="BANK ACCOUNT"
                  disabled={isDeniedUpdateBankInfo}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  onChange={this.onTextFieldChange('bank_routing')}
                  value={getAccountDataValue('bank_routing')}
                  error={this.state['bank_routing_error']}
                  placeholder=""
                  label="BANK ROUTING"
                  disabled={isDeniedUpdateBankInfo}
                />
              </Grid>
            </Grid>
            {bankRequest?.id && (
              <Box mt={2}>
                <Box my={1} display="flex">
                  <Box className={classes.flexItem}>Status:</Box>
                  <Box className={classes.flexItem}>
                    <Box component="strong" style={{ color: BANK_REQUEST_STATUS[bankRequest?.status] }}>
                      {bankRequest?.status}
                    </Box>
                  </Box>
                  <Box className={classes.flexItem}>at</Box>
                  <Box className={classes.flexItem}>
                    <em>
                      {moment(bankRequest?.status === 'SUBMITTED' ? bankRequest?._updated : bankRequest?.approved_date).format('YYYY-MM-DD HH:mm')}
                    </em>
                  </Box>
                </Box>
                {bankRequest?.status === 'SUBMITTED' && (
                  <Box style={{ padding: 8, backgroundColor: '#f2f1ff', width: 330 }}>
                    <Grid container spacing={4}>
                      <Grid item>
                        <Box>
                          <strong>Bank Account</strong>
                        </Box>
                        <Box>{bankRequest?.new_account_number}</Box>
                      </Grid>
                      <Grid item>
                        <Box>
                          <strong>Bank Routing</strong>
                        </Box>
                        <Box>{bankRequest?.new_routing_number}</Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            )}
            <Box textAlign="right" mt={2}>
              {!isDeniedUpdateBankInfo && (
                <Button
                  disabled={!!hasBankError || updating}
                  onClick={this.submitBankRequest}
                  className={classes.mainBtn}
                  size="medium"
                  disableElevation={true}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        </Box>

        <AxlDialog
          isOpen={showBankConfirmation}
          handleClose={() => this.setState({ showBankConfirmation: false })}
          maxWidth="sm"
          alignTitle="center"
          childrenTitle={<Box fontWeight={700}>Bank Info Update Confirmation</Box>}
        >
          <Box p={2} textAlign="center" lineHeight={1.5}>
            Thank you for submitting the request. We will review the bank update request within 1-3 working days and will reach out to you directly
            with any questions.
          </Box>
        </AxlDialog>
      </Container>
    );
  }
}

const AccountCompose = compose(inject('store'), observer)(Account);

export default withStyles(styles)(AccountCompose);
