import React, {Component, Fragment} from 'react';
import { Box, Divider, Grid, Rating } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import {Star as StarIcon} from "@mui/icons-material";
import moment from "moment";
import {AxlButton, AxlPanel} from "axl-reactjs-ui";
import ReasonDialog from "../ReasonDialog";
import styles from './styles';
import DriverProfileRoutingTabs from "../DriverProfileRoutingTabs";
import _ from 'lodash';
import AxlModal from "../../AxlMUIComponent/AxlModal";
import DriverProfileInformation from "../DriverProfileInformation";
import {compose} from "recompose";
import {inject, observer} from "mobx-react";

import acl from 'constants/acl';

class DriverInfoCardCompose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      showInfo: false,
      showDriverPicture: false,
      showUnassignDriver: false,
      showDriverProfile: false
    }
  }

  showUnassignDriver = () => {
    this.setState({showUnassignDriver: true});
  };

  hideUnassignDriver = () => {
    this.setState({showUnassignDriver: false, reason: ''});
  };

  confirmUnassignDriver = () => {
    const {reason} = this.state;
    // if (!reason) return;

    this.props.onUnAssignDriver(reason);
  };

  reassign = () => {
    this.hideUnassignDriver();
    this.props.showDriverAssign();
  };

  onShowDriverProfile = () => {
    if (this.props.driver) {
      this.setState({showDriverProfile: true});
    }
  };

  onHideDriverProfile = () => {
    this.setState({showDriverProfile: false});
  };

  // todo: Show driver profile modal
  render() {
    const { showUnassignDriver, reason, showInfo } = this.state;
    const { driver, driverScore, isActivated, isCompleted, store, classes } = this.props;
    const { assignmentStore, permissionStore } = store;

    const {unAssigning, loadingAssignment} = assignmentStore;
    const assigmentLabel = _.get(this, 'props.assignment.assignment.label', 'N/A');
    const canUnassign = driver && !isActivated && !isCompleted;
    const driverData = driver ? {
      ...driver,
      driverScore: this.props.driverScore ? this.props.driverScore : 0
    } : {
      fake: true,
      first_name: '',
      last_name: '',
      status: '-',
      driverScore: 0,
      phone_number: '-',
      driver_license: '-',
      ssn_verified_by: '-',
      vehicle_make: '-',
      vehicle_model: '-',
      vehicle_color: '-',
      vehicle_license_plate: '-',
      background_decision_ts: '-',
      vehicle_license_plate_state: '-'
    };
    const driverDataMap = [
      {label: 'Phone', key: 'phone_number'},
      {label: 'Status', key: 'status'},
      {label: 'AHID', key: 'id'},
      {label: 'Joined', key: 'background_decision_ts'},
      {label: 'D/L No.', key: 'driver_license'},
    ];

    const isDeniedAssignDriver = permissionStore.isDenied(acl.ASSIGNMENTS.ASSIGN);

    return (
      <Box className={classes.container} p={1}>
        <Grid container alignItems="center" justifyContent="space-between" style={{marginBottom: 10}}>
          <Grid item>
            <span className={classes.label2}>Driver Info:</span>
          </Grid>
          {!isDeniedAssignDriver && (
            <Grid item>
              {canUnassign && (
                <Fragment>
                  <AxlButton
                    onClick={this.showUnassignDriver}
                    tiny={true}
                    bg="none"
                    disabled={driver && (isActivated || isCompleted)}
                    style={{ margin: '0 3px' }}
                  >
                    Unassign Driver
                  </AxlButton>
                  <AxlButton onClick={this.reassign} bg="none" tiny={true}>
                    Reassign
                  </AxlButton>
                </Fragment>
              )}
              {!canUnassign && (
                <AxlButton
                  onClick={this.reassign}
                  className={classes.button}
                  bg="periwinkle"
                  disabled={driver && (isActivated || isCompleted)}
                  tiny={true}
                >
                  {!driver ? 'Assign' : 'Unassign'}
                </AxlButton>
              )}
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item className={classes.driverInfoLeft}>
            <Box className={classes.driverPhotoContainer}>
              {_.has(driverData, 'id') && <AxlModal className={classes.modalStyle} bgcolor={'primary.white'} trigger={<Box className={classes.photo}>
                  {_.has(driverData, 'photo') ? <img alt="" src={driverData.photo} className={classes.driverPhoto} /> : <div className={classes.defaultDriverPhoto}/>}
                </Box>}>
                <DriverProfileInformation driver={driverData} />
                <DriverProfileRoutingTabs driver={driverData} onSave={this.onHideDriverProfile} history={this.props.history} isShowAction={false} />
              </AxlModal>}
            </Box>
          </Grid>
          <Grid item className={classes.driverInfoRight}>
            <Fragment>
                <Box className={classes.driverName}>{driverData.first_name} {driverData.last_name}</Box>
                <Rating size="small"
                        name="driver-score"
                        emptyIcon={<StarIcon color="inherit" fontSize="inherit" />}
                        icon={<StarIcon color="primary" fontSize="inherit" />}
                        readOnly={!driverScore}
                        value={driverScore}
                />
                <Grid container spacing={1}>
                  {driverDataMap.map((field, index) => (
                    <Grid item xs ={6} key={index}>
                      <Box className={classes.itemWrapper}>
                        <Box component="span" className={classes.label}>{field.label}</Box>
                        <Box component="span" className={classes.text}>
                          {field.key !== 'id' && moment(driverData[field.key]).isValid() ? moment(driverData[field.key]).format('MMM YYYY') : driverData[field.key]}
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Fragment>
          </Grid>
        </Grid>
        <ReasonDialog
          loading={unAssigning || loadingAssignment}
          title={`Are you sure to unassign driver ${driverData.first_name} ${driverData.last_name} [${driverData.id}] from assignment ${assigmentLabel}?`}
          open={showUnassignDriver}
          reason={reason}
          onChange={(reason) => this.setState({reason})}
          onClose={this.hideUnassignDriver}
          onConfirm={this.confirmUnassignDriver}
          confirmText={'Unassign'}/>
        <Box my={1}>
          <Divider />
        </Box>
        <AxlPanel.Row>
          <AxlPanel.Col>
            <span className={classes.label3}>{`Vehicle Info: `}</span>
            <span className={classes.showLink} onClick={() => this.setState({showInfo: !showInfo})}>
                        {!showInfo ? 'Show Info' : 'Hide Info'}
                    </span>
          </AxlPanel.Col>
        </AxlPanel.Row>
        {this.state.showInfo && <AxlPanel.Row>
          <AxlPanel.Col>
            <AxlPanel.Row>
              <AxlPanel.Col><span className={classes.label}>{`MAKE:`}</span><span className={classes.text}>{ driverData.vehicle_make }</span></AxlPanel.Col>
              <AxlPanel.Col><span className={classes.label}>{`MODEL:`}</span><span className={classes.text}>{ driverData.vehicle_model }</span></AxlPanel.Col>
              <AxlPanel.Col><span className={classes.label}>{`COLOR:`}</span><span className={classes.text}>{ driverData.vehicle_color }</span></AxlPanel.Col>
            </AxlPanel.Row>
            <AxlPanel.Row>
              <AxlPanel.Col><span className={classes.label}>{`PLATE:`}</span><span className={classes.text}>{ driverData.vehicle_license_plate }</span></AxlPanel.Col>
              <AxlPanel.Col><span className={classes.label}>{`STATE:`}</span><span className={classes.text}>{ driverData.vehicle_license_plate_state }</span></AxlPanel.Col>
              <AxlPanel.Col></AxlPanel.Col>
            </AxlPanel.Row>
          </AxlPanel.Col>
        </AxlPanel.Row>}
      </Box>
    );
  }
}
const DriverInfoCard = compose(inject("store"), observer)(DriverInfoCardCompose);

export default withStyles(styles)(DriverInfoCard);
