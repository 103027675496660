import React, {Component, cloneElement, forwardRef} from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { Box, FormHelperText } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import styles, {InputText} from './styles';


const TextMaskPhone = forwardRef((props, setRef) => {
  return (
    <MaskedInput
      ref={innerRef => {
        if (innerRef) {
          setRef(innerRef.inputElement);
        }
      }}
      mask={['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}  
      {...props}
    />
  );
});

TextMaskPhone.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class TextInput extends Component {
  render() {
    const { prefix, isPhone, placeholder, value, id, type, label, error, okMessage, classes, onChange, button, btnClick, onMouseLeave, disabled } = this.props;
    let borderColor = "#0000003b";
    if (error && error != undefined)  borderColor = "#d0021b";
    const width = this.props.width ? this.props.width : '100%';

    const btnElement = button ? cloneElement(button, {
      onClick: btnClick,
    }) : null;

    let style = {};
    const hiddenProps = {};

    if (prefix)
      style = {paddingLeft: 0};
    if (onMouseLeave) {
      hiddenProps['onMouseLeave'] = onMouseLeave;
    }

    return (
      <Box my={0.5}>
        <p htmlFor={id} className={classes.label} dangerouslySetInnerHTML={{__html: label}} />
        <Box my={0.5} display="flex" flexDirection="row">
          <Box border={1} borderColor={borderColor} borderRadius="4px" width={width} display="flex" flexDirection="row" p={0} alignItems={'center'}>
            {prefix && <Box className={classes.prefix} p={0}>
              {prefix}
            </Box>}
            <Box width="100%">
              <InputText
                disabled={disabled}
                style={style}
                placeholder={placeholder !== undefined ? placeholder: label}
                id={id}
                type={type ? type: 'text'}
                className={classes.customInput}
                onChange={onChange}
                value={value}
                {...hiddenProps}
                inputProps={{ 'aria-label': label }}
                inputComponent={isPhone ? TextMaskPhone: undefined}
              />
            </Box> 
          </Box>
          {button && btnElement}        
          </Box>     
          
        {error && <FormHelperText style={{color: '#d0021b', fontWeight: 'bold'}}>{error}</FormHelperText>}
        {okMessage && <FormHelperText style={{color: '#76c520', fontWeight: 'bold'}}>{okMessage}</FormHelperText>}
      </Box>
    );
  }
}

export default  withStyles(styles)(TextInput);
