export const ASSIGNMENTS = {
  LIST: [{ method: 'GET', path: 'dsp/assignments/list-by-date' }],
  ASSIGN: [
    { method: 'PUT', path: 'assignments/{assignment_id}/assign' },
    { method: 'PUT', path: 'dsp/assignments/{assignment_id}/unassign' },
    { method: 'PUT', path: 'assignments/{assignment_id}/reassign' },
  ],
  ORDER_SHIPMENTS: [
    { method: 'POST', path: 'assignments/{assignment_id}/reorder-shipments' },
    { method: 'POST', path: 'assignments/{assignment_id}/reset-sequence-id' },
  ],
  UPDATE_DROP_OFF: [{ method: 'PUT', path: 'shipments/{shipment_id}/dropoff-location-info' }],
  RE_ROUTE: [{ method: 'POST', path: 'dsp/assignments/{assignment_id}/add-shipment' }],
  SPLIT_ROUTE: [{ method: 'PUT', path: 'dsp/assignments/{assignment_id}/split/{shipment_id}' }],
  UPDATE_SHIPMENT_TAG: [{ method: 'POST', path: 'shipments/{shipment_id}/tags' }],
  UPLOAD_POD: [{ method: 'POST', path: 'dsp/shipments/{shipment_id}/add-image' }],
  NOTE: [
    { method: 'PUT', path: 'notes/{assignment_id}' },
    { method: 'POST', path: 'notes/{assignment_id}' },
  ],
  ACTIVE: [
    { method: 'PUT', path: 'dsp/assignments/{assignment_id}/activate' },
    { method: 'PUT', path: 'dsp/assignments/{assignment_id}/deactivate' },
  ],
  MESSENGER: [{ method: 'POST', path: 'messenger/topics' }],
  FEEDBACK: [{ method: 'POST', path: 'stops/{stop_id}/add-feedback' }],
};

export const VEHICLES = {
  LIST: [{ method: 'GET', path: 'vehicles' }],
  EDIT: [
    { method: 'GET', path: 'vehicles/cars' },
    { method: 'POST', path: 'vehicles/upsert' },
  ],
  DELETE: [{ method: 'DELETE', path: 'vehicles/delete/{id}' }],
};

export const DRIVERS = {
  LIST: [{ method: 'GET', path: 'dsp/drivers/search' }],
  DETAIL: [{ method: 'GET', path: 'drivers/{id}' }],
  CREATE: [{ method: 'POST', path: 'dsp/drivers/create_driver' }],
};

export const PAYMENTS = {
  EVENT_LIST: [{ method: 'GET', path: 'dsp-events' }],
  EVENT_DOWNLOAD_CSV: [{ method: 'GET', path: 'reports/dsp-transactions/{id}' }],
  TRANSACTION_LIST: [{ method: 'GET', path: 'dsp-payments' }],
  TRANSACTION_DETAIL: [
    { method: 'GET', path: 'dsp-payments/{id}' },
    { method: 'GET', path: 'dsp/assignments/{id}/detail' },
  ],
  TRANSACTION_UPDATE_BANK_INFO: [{ method: 'PUT', path: 'dsp-payments/{id}/bank-info' }],
  TRANSACTION_DOWNLOAD_CSV: [{ method: 'GET', path: 'dsp-payments/{id}/csv' }],
};

export const USERS = {
  USER_LIST: [{ method: 'GET', path: 'couriers/users/list' }],
};

export const PROFILE = {
  ACCOUNT_INFO: [{ method: 'POST', path: 'couriers/info' }],
  BANK_INFO: [{ method: 'PUT', path: 'couriers/bank' }],
};

export const TICKETS = {
  VOID: [{ method: 'POST', path: 'tickets/{id}/void' }],
};

export default { ASSIGNMENTS, VEHICLES, DRIVERS, PAYMENTS, USERS, PROFILE, TICKETS };
